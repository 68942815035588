.imageContainer {
  position: relative;
  display: block;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;
  height: 200px;

  &::before {
    content: "Browse";
    color: #FFFFFF;
    text-align: center;
    width: 100%;
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 101;
    opacity: 0;
    -webkit-transition: opacity .2s ease;
    -o-transition: opacity .2s ease;
    transition: opacity .2s ease;
    pointer-events: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
  }

  &::after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background: purple;
    opacity: 0;
    -webkit-transition: opacity .2s ease;
    -o-transition: opacity .2s ease;
    transition: opacity .2s ease;
    pointer-events: none;
  }

  &:hover:before {
    opacity: 1;
  }

  &:hover:after {
    opacity: .75;
  }

  .imageLink {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
  }

  .image {
    display: block;
    width: 100%;
    height: 12em;
    object-fit: cover;
    transition: transform .2s ease;
    transform: scale(1);

    &:hover {
      transform: scale(1.2);
    }
  }
}
