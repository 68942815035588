.heroContainer {
  //background-position: center;
  //background-image: url('/assets/images/index_bg.jpg');
  min-height: calc(100vh - 70px);
  position: relative;

  .coverVideo {
    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: 100%;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
  }

  .overlay {
    //background-image: linear-gradient(-186deg, #377384 0, #305a6e 53%, #232f49 84%);
    //background-color: #2c3e50;
    //background-image: linear-gradient(-186deg, #17bc9cde 0, #17bc9c6e 53%, #17bc9c78 84%);
    //background-color: #2d2f31;
    background-color: #b3beca;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.6;
    z-index: -1
  }
}
